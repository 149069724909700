import { createWebHistory, createRouter } from "vue-router";

const routes = [
    {
        path: "/",
        redirect: "/login",
        name: "default",
    },
    {
        path: "/login",
        name: "login",
        component: () => import("@/views/PageLogin"),
    },
    {
        path: "/home",
        name: "home",
        component: () => import("@/views/PageHome"),
    },
    {
        path: "/state-enterprise",
        name: "stateEnterprise",
        component: () => import("@/views/PageEnterprise"),
    },
    {
      path: "/requests",
      name: "requests",
      component: () => import("@/views/PageRequests"),
    },
    {
      path: "/request-info",
      name: "requestInfo",
      component: () => import("@/views/PageRequestsInfo"),
      props: (route) => {
          return {
              isCreateRequest: !!route.query.create,
              childRoute: route.query.tab,
          }
      },
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});
export default router;
