<template>
  <router-view/>
</template>

<script>
export default {
  name: 'App',
  components: {}
}
</script>

<style>
  @font-face {
    font-family: "Roboto";
    src: url("~@/assets/fonts/Roboto-Medium.eot") format("eot"),
    url("~@/assets/fonts/Roboto-Medium.ttf") format("ttf"),
    url("~@/assets/fonts/Roboto-Medium.woff") format("woff"),
    url("~@/assets/fonts/Roboto-Medium.woff2") format("woff2");
    font-style: normal;
    font-weight: 500;
  }

  @font-face {
    font-family: "Roboto";
    src: url("~@/assets/fonts/Roboto-Regular.eot") format("eot"),
    url("~@/assets/fonts/Roboto-Regular.ttf") format("ttf"),
    url("~@/assets/fonts/Roboto-Regular.woff") format("woff"),
    url("~@/assets/fonts/Roboto-Regular.woff2") format("woff2");
    font-style: normal;
    font-weight: 400;
  }

  @font-face {
    font-family: "San Francisco Pro";
    src: url("~@/assets/fonts/SFProDisplay-Regular.eot") format("eot"),
    url("~@/assets/fonts/SFProDisplay-Regular.ttf") format("ttf"),
    url("~@/assets/fonts/SFProDisplay-Regular.woff") format("woff");
    font-style: normal;
    font-weight: 400;
  }
  :root {
    --black-bg: #1c1c1e;
    --black-light: #262626;
    --white-text: #F8FAFC;
    --gray-text: #757676;
    --default-text: #C1C3C4;
    --primary-color: #660FAB;
    --input-color: #353739;
    --secondary-color: #10848F;
    --option-color: #989999;
    --success-color: #20B661;
    --failure-color: rgba(255, 0, 0, 0.60);
    --header-height: 45px;
    --footer-height: 45px;
    --widthBaseContainer: 1824px;
  }
  * {
    box-sizing: border-box;
  }
  html {
    font-family: "Roboto", sans-serif;
  }
  body {
    margin: 0;
    padding: 0;
    color: var(--default-text);
    font-weight: 400;
  }
  ul {
    margin: 0;
    padding: 0;
  }
  .subtitle, .subtitle-2 {
    font-family: "San Francisco Pro", sans-serif;
    font-size: 16px;
    color: var(--white-text);
  }
  .subtitle-2 {
    font-size: 14px;
  }
  .gray-label {
    font-family: "San Francisco Pro", sans-serif;
    color: var(--gray-text);
    font-size: 14px;
  }

  .label-12 {
    font-size: 12px;
  }
  .ml-4 {
    margin-left: 16px;
  }

  .pa-6 {
    padding: 24px;
  }

  .title-24 {
    font-family: "San Francisco Pro", sans-serif;
    font-size: 24px;
    color: var(--white-text);
  }
</style>
